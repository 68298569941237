/* JavaScript File                                                  */
/* lazyload-image.js                                                */
/* Modified April 6, 2020                                           */


var lazyloader = (function(document, window) {

	var evt = [

		// lazy loader - function that runs before an image is unveiled 

		function(document, window) {

			window.lazySizesConfig = window.lazySizesConfig || {};
			
			window.lazySizesConfig.lazyClass = 'lazyload';
			window.lazySizesConfig.loadedClass = 'lazyloaded';
			window.lazySizesConfig.loadingClass = 'lazyloading';
			window.lazySizesConfig.autosizesClass = 'autosizes';
			
			document.addEventListener('lazyloaded', function(e) {
				
				if (e.target.classList.contains('cover')) {

					if(e.target.parentNode.tagName.toLowerCase() === 'picture') {
						
						e.target.parentNode.parentNode.classList.add('cover-loaded');
					} else {

						e.target.parentNode.classList.add('cover-loaded');
					}
				}
			});
		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

lazyloader.init();

